<template>
    <div class="overflow-y-scroll full-height full-width">
        <template v-if="loading">
            <v-container fluid class="pa-6 full-height full-width">
                <div class="full-height full-width d-flex align-center justify-center">
                    <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                </div>
            </v-container>
        </template>

        <template v-else>
            <v-container fluid class="pa-6">
                <v-row class="d-flex">
                    <v-col cols="6">
                        <h3>Competitions</h3>
                    </v-col>
                    <v-col class="d-flex" cols="6">
                        <v-btn class="ml-auto" :to="{ name: 'admin.competition.create' }" color="primary" small>
                            <v-icon color="white" small>mdi-plus</v-icon> Create Competition
                        </v-btn>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" lg="2" v-for="competition in competitions" :key="competition._id">
                        <basic-tile
                            :item="competition"
                            @click="$router.push({ name: 'admin.competition', params: { id: competition._id } })"
                        >
                            <template name="default">
                                <template v-if="isSandbox(competition)">
                                    <div class="text-overflow warning--text">
                                        <span class="basic-tag warning">sandbox</span>
                                    </div>
                                </template>

                                <template v-else>
                                    <div class="text-overflow success--text">
                                        <span class="basic-tag success">live</span>
                                    </div>
                                </template>
                            </template>
                        </basic-tile>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </div>
</template>

<script>
// Services
import Competition from "@/modules/app-module/competition";

// Packages
import BasicTile from "@/components/global/basic-tile.vue";

export default {
    name: "admin-competitions",

    meta: {
        title: "Competitions",
    },

    props: {},

    components: {
        BasicTile,
    },

    data() {
        return {
            competitions: [],
            loading: false,
        };
    },

    computed: {},

    methods: {
        async init() {
            this.loading = true;

            try {
                const competitionQuery = {
                    status: "active",
                    definition: "competition",
                    realms: {
                        $in: this.$app.liveRealm,
                    },
                };
                this.competitions = await Competition.query(competitionQuery).then(({ data }) => data);
                console.log("COMPETITIONS", this.competitions);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
        hasImage(competition) {
            let image = competition?.data?.image;

            return image?._id || image;
        },
        isSandbox(competition) {
            return competition?.realms?.some((realm) => realm._id == this.$app.sandboxRealm);
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
